import Dependent from '../dependent/Dependent';
import TextComponent from '../../components/text/TextComponent';
import SketchComponentType from '../../components/SketchComponentType';
import IGraphic from '../../graphic/IGraphic';
import IPagesComponentTree from '../../component-tree/IPagesComponentTree';

interface IPageTextOverflowObserver {
	componentTree: IPagesComponentTree,
}

class PageTextOverflowObserver extends Dependent<IPageTextOverflowObserver> {
	private readonly overflowGraphics: IGraphic[];
	private readonly overflowListeners: VoidFunction[];
	private readonly notOverflowListeners: VoidFunction[];

	constructor() {
		super();
		this.overflowGraphics = [];
		this.overflowListeners = [];
		this.notOverflowListeners = [];

		// setTimeout(() => {
		// 	const components = new Set(this.overflowGraphics.map(graphic => graphic.getParentComponent()));
		// 	components.forEach(component => {
		// 		if (component !== null) {
		// 			component.enableFocus();
		// 		}
		// 	});
		// 	console.log('focus');
		// }, 10000);
	}

	public sync = () => {
		const textComponents = this.dependencies
			.componentTree.getUniformComponents<TextComponent>(SketchComponentType.TEXT);
		if (textComponents === null) {
			this.callNotOverflowListeners();
			return;
		}

		const uniqueTextGraphic: Set<IGraphic> = new Set(textComponents
			.map(component => component.getGraphics())
			.flat());
		const pages = this.dependencies.componentTree.getPages();

		this.overflowGraphics.length = 0;
		for (let i = 0; i < pages.length; i++) {
			const graphics = this.dependencies.componentTree.getPageEmbeddedGraphics(i);
			if (graphics !== null) {
				for (let j = 0; j < graphics.length; j++) {
					if (uniqueTextGraphic.has(graphics[j])) {
						const graphicPosition = graphics[j].getGlobalPosition();
						const graphicConfiguration = graphics[j].getFrameConfiguration();
						const pagePosition = pages[i].getGlobalPosition();
						const pageConfiguration = pages[i].getFrameConfiguration();

						if (graphicPosition.x < pagePosition.x || graphicPosition.y < pagePosition.y
							|| graphicPosition.x + graphicConfiguration.width
							> pagePosition.x + pageConfiguration.width
							|| graphicPosition.y + graphicConfiguration.height
							> pagePosition.y + pageConfiguration.height) {
							this.overflowGraphics.push(graphics[j]);
						}
					}
				}
			}
		}

		if (this.overflowGraphics.length === 0) {
			this.callNotOverflowListeners();
			return;
		}

		this.overflowGraphics.forEach(graphic => {
			console.log(graphic.getTexture());
		});

		this.callOverflowListeners();
	};

	public getOverflowGraphics = (): IGraphic[] | null => (this.overflowGraphics.length === 0
		? null
		: [...this.overflowGraphics]);

	public addOverflowListeners = (listener: VoidFunction) => {
		this.overflowListeners.push(listener);
	};

	public addNotOverflowListeners = (listener: VoidFunction) => {
		this.notOverflowListeners.push(listener);
	};

	private callOverflowListeners = () => {
		for (let i = 0; i < this.overflowListeners.length; i++) {
			this.overflowListeners[i]();
		}
	};

	private callNotOverflowListeners = () => {
		for (let i = 0; i < this.notOverflowListeners.length; i++) {
			this.notOverflowListeners[i]();
		}
	};
}

export default PageTextOverflowObserver;
